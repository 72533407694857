import DisplayInfo from './DisplayInfo.js'
import '../styles/ui.css'
import { isMobile } from 'react-device-detect'
import useVideoManager from '../hooks/useVideoManager.js'
import useAudioManager from '../hooks/useAudioManager'
import useUIManager from '../hooks/useUIManager.js'
let data = require('../data.json')

const Arrows = function () {
  return (
    <div className="arrows">
      <div className="arrows-row">
        <img alt="arrow-up" className="arrow" src="UI/Up.png"></img>
      </div>
      <div className="arrows-row">
        <img alt="arrow-left" className="arrow" src="UI/Left.png"></img>
        <img alt="arrow-down" className="arrow" src="UI/Down.png"></img>
        <img alt="arrow-right" className="arrow" src="UI/Right.png"></img>
      </div>
    </div>
  )
}

function UI(props) {
  const { helpContainer, cubeButton, aboutContainer, socialmedia } = data
  const { mobile, desktop } = helpContainer

  const setVisible = useVideoManager((state) => state.setVisible)

  const bio = aboutContainer.bio.map((s, i) => {
    return i === 0 ? <b key={'bio-' + i}>{s}</b> : <p key={'bio-' + i}>{s}</p>
  })
  const artwork = aboutContainer.artwork.map((s, i) => {
    return i === 0 ? <b key={'bio-' + i}>{s}</b> : <p key={'bio-' + i}>{s}</p>
  })
  // const artsensation = aboutContainer.artsensation
  // const artscouncil = aboutContainer.artscouncil

  const { email, instagram, web } = socialmedia

  const updateUI = useUIManager((state) => state.update)
  const firstGesture = useUIManager((state) => state.firstGesture)

  const didLoad = (state) => {
    const v = Object.values(state.loadingResources).every(Boolean)
    return v
  }

  const resourcesLoaded = useUIManager(didLoad)
  const refs = useAudioManager((state) => state.audiosRef)
  const allRefs = useAudioManager((state) => state.allRefs)
  const player = useVideoManager((state) => state.player)
  const transition = useUIManager((state) => state.transition)
  return (
    <>
      {resourcesLoaded && allRefs && player ? (
        <main>
          <DisplayInfo
            hideClose={transition}
            id="helpContainer"
            src="UI/Question.png"
            styleClass="help"
            init={!firstGesture}
            onShowCallback={() => setVisible('video', false)}
            onHideCallback={() => null}
          >
            <p>{isMobile ? mobile[0] : desktop[0]}</p>
            <p>{isMobile ? mobile[1] : desktop[1]}</p>
            {isMobile ? null : <Arrows />}
            {transition ? (
              <button
                onClick={() => {
                  updateUI('firstGesture', true)

                  Object.values(refs).map((ref, i) => {
                    if (ref.source) {
                      const audioCtx = ref.source.context
                      if (audioCtx.state === 'suspended') {
                        audioCtx.resume().then(function () {
                          console.log('playing')
                          ref.play(0)
                        })
                      }
                    }
                    ref.play(0)
                    return null
                  })
                  player.setVolume(0)
                  player.playVideo()
                }}
                className="enter"
              >
                Enter
              </button>
            ) : null}
          </DisplayInfo>
          <DisplayInfo
            openText={aboutContainer.open}
            styleClass="about"
            show={false}
            {...props}
            onShowCallback={() => setVisible('video', false)}
            onHideCallback={() => setVisible('video', true)}
          >
            <h1 className="about-title">{aboutContainer.title}</h1>
            <div className="about-text" id="about">
              {bio} {artwork}
              <a href={aboutContainer.artsensation.url}>
                {aboutContainer.artsensation.title}
              </a>
              <br />
              <br />
              <a href={aboutContainer.artscouncil.url}>
                {aboutContainer.artscouncil.title}
              </a>
              <br />
              <br />
            </div>

            <div className="social-networks">
              {email ? (
                <a href={email}>
                  <img alt="mail-src" src="UI/mail.png" />
                </a>
              ) : null}
              {instagram ? (
                <a href={instagram}>
                  <img alt="insta-src" src="UI/insta.png" />
                </a>
              ) : null}
              {web ? (
                <a href={web} className="invert">
                  <img alt="web-src" src="UI/Web.png" />
                </a>
              ) : null}
            </div>
          </DisplayInfo>

          <button
            id="cubContainer"
            className="cube"
            type="button"
            onClick={() => window.open(cubeButton.webpage, 'blank')}
          >
            <img alt="cube-src" src="UI/Cube.png" />
          </button>
        </main>
      ) : null}
    </>
  )
}

export default UI
