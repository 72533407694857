import MobileViewer from './components/MobileViewer.js'
import Audio from './components/Audio.js'
import Lights from './components/Lights.js'
import Viewer from './components/Viewer.js'
import Cube from './components/Cube.js'
import Model from './components/Model.js'
import Stream from './components/StreamSimple.js'
import { Canvas } from '@react-three/fiber'
import { useState, Suspense } from 'react'
import { isMobile } from 'react-device-detect'
import ErrorBoundary from './components/ErrorBoundary.js'
import { hot } from 'react-hot-loader/root'
import useUIManager from './hooks/useUIManager.js'

function App() {
  const x = 25
  const y = 25 * 0.64
  const z = 25
  const cubeSize = { x, y, z }

  const [, setUserinteracion] = useState(true)
  const transition = useUIManager((state) => state.transition)
  const cameraOptions = {
    far: 100,
    fov: 60,
    aspect: window.width / window.height,
    position: [12, 1, 12],
    rotation: [0.10651402595328875, 0.7661758723404811, -0.07856151004722654],
  }

  const modelPath = 'models/'

  return (
    <>
      <ErrorBoundary>
        <Canvas dpr={[2, 1]} shadows camera={cameraOptions}>
          <Suspense fallback={null}>
            <Model
              scale={0.0003}
              name="Crystal_Cavern_with_scan"
              path={modelPath}
              mtlFile="Crystal_Cavern_with_scan.mtl"
              objFile="Crystal_Cavern_with_scan.obj"
              position={[1, 0, 1]}
            />
            {!transition ? (
              <>
                {!isMobile ? (
                  <Viewer
                    position={[0, -cubeSize.y / 4, (3 * cubeSize.z) / 5]}
                    callback={setUserinteracion}
                  />
                ) : (
                  <MobileViewer callback={setUserinteracion} />
                )}
              </>
            ) : null}

            <Audio />
            <Lights />
            <Cube size={cubeSize} />
            <Stream
              url={'https://www.youtube.com/watch?v=u4O0wthnhCU'}
              position={[0, 1, 0]}
              rotation={[0, Math.PI / 2, 0]}
              scale={0.25}
            />
          </Suspense>
        </Canvas>
      </ErrorBoundary>
      {/* <GUIControls /> */}
    </>
  )
}

export default hot(App)
