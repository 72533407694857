import { useRef } from "react";
import * as THREE from "three";

function Cube({ size }) {
  const { x, y, z } = size;
  const mesh = useRef();

  const color1 = "rgb(190,188,188)";
  const color2 = "rgb(102,102,102)";

  return (
      <mesh ref={mesh} position={[0, 0, 0]} receiveShadow>
        <boxBufferGeometry attach="geometry" args={[x, y, z]} />
        {/*right*/}
        <meshStandardMaterial
          attachArray="material"
          side={THREE.BackSide}
          color={color1}
          roughness={0.8}
          />
        {/*left*/}
        <meshStandardMaterial
          attachArray="material"
          side={THREE.BackSide}
          color={color1}
          roughness={0.8}
          />
        {/*top*/}
        <meshStandardMaterial
          attachArray="material"
          side={THREE.BackSide}
          color={color2}
          roughness={0.8}
          />
        {/*floor*/}
        <meshStandardMaterial
          attachArray="material"
          side={THREE.BackSide}
          color={color2}
          roughness={0.8}
          />
        {/*back*/}
        <meshStandardMaterial
          attachArray="material"
          side={THREE.BackSide}
          color={color1}
          roughness={0.8}
          />
        <meshStandardMaterial
          attachArray="material"
          side={THREE.BackSide}
          color={color1}
          roughness={0.8}
          />
      </mesh>
  );
}

export default Cube;
