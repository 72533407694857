import { useState, useEffect, Suspense } from 'react'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

function Model({
  path,
  objFile,
  mtlFile,
  onSuccess,
  position,
  scale,
  ...props
}) {
  const INIT = 0
  const LOADING = 1
  const READY = 2
  const [state, setState] = useState(INIT)
  const [model, setModel] = useState(INIT)

  useEffect(() => {
    if (state === READY || state === LOADING) return

    setState(LOADING)
    var mtlLoader = new MTLLoader()
    mtlLoader.setPath(path)
    mtlLoader.setMaterialOptions({ invertTrProperty: true })
    mtlLoader.load(mtlFile, function (materials) {
      materials.preload()
      var objLoader = new OBJLoader()
      objLoader.setPath(path)
      objLoader.setMaterials(materials)

      objLoader.load(
        objFile,
        function (object) {
          setModel(object)
          setState(READY)
        },
        (xhr) => {
          const normalizedLoad = xhr.loaded / xhr.total

          if (normalizedLoad === 1) {
            console.log(normalizedLoad * 100 + '% loaded ')
          }
        },
        (error) => {
          console.log(error)
        },
      )
    })
  }, [state, path, objFile, mtlFile])

  return state === READY ? (
    <Suspense fallback={null}>
      <primitive position={position} object={model} scale={scale} />
    </Suspense>
  ) : null
}

export default Model
