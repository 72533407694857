import create from 'zustand'

const useVideoManager = create((set) => ({
  didLoad: {
    video: false,
  },
  visible: {
    video: false,
  },
  setVisible: (type, loaded) =>
    set((state) => {
      return {
        ...state,
        visible: { [type]: loaded },
      }
    }),
  setLoaded: (type, loaded) =>
    set((state) => {
      return {
        ...state,
        didLoad: { [type]: loaded },
      }
    }),
  player: null,
  set: (key, value) =>
    set((state) => {
      return {
        ...state,
        [key]: value,
      }
    }),
}))

export default useVideoManager
