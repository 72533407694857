import PositionalSound from './PositionalSound.js'
import useAudioManager from '../hooks/useAudioManager'
import useUIManager from '../hooks/useUIManager'
import { useEffect } from 'react'
const main = {
  audio: {
    position: [4, 0, 4],
    path: '/audio/alexandra-audio-main.mp3',
    refdistance: 1,
    rollofffactor: 3,
    cone: {
      outer: 360,
      inner: 180,
      gain: 1,
    },
    rotation: [0, Math.PI / 2, 0],
    maxdistance: 20,
    distancemodel: 'inverse',
  },
}

const film = {
  audio: {
    position: [0, 0, 0],
    path: '/audio/alexandra-audio-film.mp3',
    refdistance: 1,
    rollofffactor: 3,
    cone: {
      outer: 360,
      inner: 180,
      gain: 1,
    },
    rotation: [0, Math.PI / 2, 0],
    maxdistance: 20,
    distancemodel: 'inverse',
  },
}

function Audio() {
  const allLoaded = useAudioManager((state) => state.allLoaded)
  const setResource = useUIManager((state) => state.resourceLoaded)

  useEffect(() => {
    if (allLoaded) {
      setResource('audio', true)
    }
  }, [allLoaded, setResource])

  return (
    <>
      <PositionalSound id={'audio'} data={main} />
      <PositionalSound id={'audio'} data={film} />
    </>
  )
}

export default Audio
